import { gql } from '../__generated__'

export const CREATE_ORGANIZATION = gql(`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const UPDATE_ORGANIZATION = gql(`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      ...OrganizationFullFragment
    }
  }
`)

export const UPDATE_ORGANIZATION_INTERNAL = gql(`
  mutation UpdateOrganizationInternal($input: UpdateOrganizationInternalInput!) {
    updateOrganizationInternal(input: $input) {
      ...OrganizationFullFragment
    }
  }
`)

export const DELETE_ORGANIZATION = gql(`
  mutation DeleteOrganization($deleteOrganizationId: UUID!) {
    deleteOrganization(id: $deleteOrganizationId) {
      ...EmptyResponseFragment
    }
  }
`)

export const UPDATE_ACCOUNT = gql(`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      ...CurrentUserAccountFragment
      profileImage {
        id
        presignedUrl
        thumbnailUrl
      }
    }
  }
`)

export const UPDATE_ACCOUNT_WIDGETS = gql(`
  mutation updateAccountWidgets($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      widgets
    }
  }
`)

export const UPDATE_ACCOUNT_CALENDARS = gql(`
  mutation updateAccountCalendars($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      isGoogleCalendarEnabled
      googleCalendarAuthorizationUrl
      googleAccountEmail
      calendars {
        ...CalendarFragment
      }
    }
  }
`)

export const SAVE_PROJECT_TYPE = gql(`
  mutation saveProjectType($input: SaveProjectTypeInput!) {
    saveProjectType(input: $input) {
      ...ProjectTypeFragment
    }
  }
`)

export const UPDATE_PROJECT_TYPE = gql(`
  mutation updateProjectType($input: UpdateProjectTypeInput!) {
    updateProjectType(input: $input) {
      ...ProjectTypeFragment
    }
  }
`)

export const SAVE_TIME_FACTOR = gql(`
  mutation saveTimeFactor($input: SaveTimeFactorInput!) {
    saveTimeFactor(input: $input) {
      ...TimeFactorFragment
    }
  }
`)

export const UPDATE_TIME_FACTOR = gql(`
  mutation updateTimeFactor($input: UpdateTimeFactorInput!) {
    updateTimeFactor(input: $input) {
      ...TimeFactorFragment
    }
  }
`)

export const SAVE_CLIENT = gql(`
  mutation saveClient($input: SaveClientInput!) {
    saveClient(input: $input) {
      ...ClientResponseFragment
    }
  }
`)

export const UPDATE_CLIENT = gql(`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      ...ClientResponseFragment
    }
  }
`)

export const SAVE_PRODUCER = gql(`
  mutation saveProducer($input: SaveProducerInput!) {
    saveProducer(input: $input) {
      ...ProducerFragment
    }
  }
`)

export const SAVE_PRODUCER_FULL = gql(`
  mutation saveProducerFull($input: SaveProducerInput!) {
    saveProducer(input: $input) {
      ...ProducerFragment
      contacts {
        ...ContactFragment
      }
    }
  }
`)

export const UPDATE_PRODUCER = gql(`
  mutation updateProducer($input: UpdateProducerInput!) {
    updateProducer(input: $input) {
      ...ProducerFragment
    }
  }
`)

export const SAVE_JOB_TITLE = gql(`
  mutation saveJobTitle($input: SaveJobTitleInput!) {
    saveJobTitle(input: $input) {
      ...JobTitleFragment
    }
  }
`)

export const UPDATE_JOB_TITLE = gql(`
  mutation updateJobTitle($input: UpdateJobTitleInput!) {
    updateJobTitle(input: $input) {
      ...JobTitleFragment
    }
  }
`)

export const SAVE_SIZE_STANDARD = gql(`
  mutation saveSizeStandard($input: SaveSizeStandardInput!) {
    saveSizeStandard(input: $input) {
      ...SizeStandardFragment
    }
  }
`)

export const UPDATE_SIZE_STANDARD = gql(`
  mutation updateSizeStandard($input: UpdateSizeStandardInput!) {
    updateSizeStandard(input: $input) {
      ...SizeStandardFragment
    }
  }
`)

export const SAVE_SIZE = gql(`
  mutation saveSize($input: SaveSizeInput!) {
    saveSize(input: $input) {
      ...SizeFragment
    }
  }
`)

export const UPDATE_SIZE = gql(`
  mutation updateSize($input: UpdateSizeInput!) {
    updateSize(input: $input) {
      ...SizeFragment
    }
  }
`)

export const DELETE_SIZES = gql(`
  mutation deleteSizes($input: DeleteSizeInput!) {
    deleteSizes(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const SAVE_CONTACT = gql(`
  mutation saveContact($input: SaveContactInput!) {
    saveContact(input: $input) {
      ...ContactFragment
    }
  }
`)

export const UPDATE_CONTACT = gql(`
  mutation updateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      ...ContactFragment
    }
  }
`)

export const DELETE_CONTACTS = gql(`
  mutation deleteContacts($input: DeleteContactInput!) {
    deleteContacts(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const SAVE_BASE_MILESTONE = gql(`
  mutation saveBaseMilestone($input: SaveBaseMilestoneInput!) {
    saveBaseMilestone(input: $input) {
      ...BaseMilestoneFragment
    }
  }
`)

export const UPDATE_BASE_MILESTONE = gql(`
  mutation updateBaseMilestone($input: UpdateBaseMilestoneInput!) {
    updateBaseMilestone(input: $input) {
      ...BaseMilestoneFragment
    }
  }
`)

export const SAVE_TEAM = gql(`
  mutation saveTeam($input: SaveTeamInput!) {
    saveTeam(input: $input) {
      ...TeamFragment
    }
  }
`)

export const UPDATE_TEAM = gql(`
  mutation updateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      ...TeamFragment
    }
  }
`)

export const SAVE_PROCEDURE = gql(`
  mutation saveProcedure($input: SaveProcedureInput!) {
    saveProcedure(input: $input) {
      ...ProcedureFragment
    }
  }
`)

export const UPDATE_PROCEDURE = gql(`
  mutation updateProcedure($input: UpdateProcedureInput!) {
    updateProcedure(input: $input) {
      ...UpdateProcedureFragment
    }
  }
`)

export const ADD_PROCEDURE_MILESTONE = gql(`
  mutation addProcedureMilestone($input: AddProcedureMilestoneInput!) {
    addProcedureMilestone(input: $input) {
      ...ProcedureMilestoneFragment
    }
  }
`)

export const UPDATE_PROCEDURE_MILESTONE = gql(`
  mutation updateProcedureMilestone($input: UpdateProcedureMilestoneInput!) {
    updateProcedureMilestone(input: $input) {
      ...ProcedureMilestoneFullFragment
    }
  }
`)

export const DELETE_PROCEDURE_MILESTONES = gql(`
  mutation deleteProcedureMilestones($input: DeleteProcedureMilestoneInput!) {
    deleteProcedureMilestones(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const ADD_PROCEDURE_TASK = gql(`
  mutation AddProcedureTask($input: AddProcedureTaskInput!) {
    addProcedureTask(input: $input) {
      ...ProcedureTaskFragment
    }
  }
`)

export const UPDATE_PROCEDURE_TASK = gql(`
  mutation UpdateProcedureTask($input: UpdateProcedureTaskInput!) {
    updateProcedureTask(input: $input) {
      ...ProcedureTaskFragment
    }
  }
`)

export const DELETE_PROCEDURE_TASKS = gql(`
  mutation DeleteProcedureTasks($input: DeleteProcedureTasksInput!) {
    deleteProcedureTasks(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const POSITION_PROCEDURE_TASK = gql(`
  mutation PositionProcedureTask($input: PositionProcedureTaskInput!) {
    positionProcedureTask(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const SAVE_LABEL = gql(`
  mutation saveLabel($input: SaveLabelInput!) {
    saveLabel(input: $input) {
      ...LabelFragment
    }
  }
`)

export const UPDATE_LABEL = gql(`
  mutation updateLabel($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      ...LabelFragment
    }
  }
`)

export const DELETE_LABEL = gql(`
  mutation deleteLabel($input: DeleteLabelInput!) {
    deleteLabel(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const SAVE_CATEGORY = gql(`
  mutation saveCategory($input: SaveCategoryInput!) {
    saveCategory(input: $input) {
      ...CategoryFragment
    }
  }
`)

export const UPDATE_CATEGORY = gql(`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      ...CategoryFragment
    }
  }
`)

export const SAVE_SUPPLIER = gql(`
  mutation saveSupplier($input: SaveSupplierInput!) {
    saveSupplier(input: $input) {
      ...SupplierFullFragment
    }
  }
`)

export const UPDATE_SUPPLIER = gql(`
  mutation updateSupplier($input: UpdateSupplierInput!) {
    updateSupplier(input: $input) {
      ...SupplierFullFragment
    }
  }
`)

export const SAVE_MANUFACTURER = gql(`
  mutation saveManufacturer($input: SaveManufacturerInput!) {
    saveManufacturer(input: $input) {
      ...ManufacturerFullFragment
    }
  }
`)

export const UPDATE_MANUFACTURER = gql(`
  mutation updateManufacturer($input: UpdateManufacturerInput!) {
    updateManufacturer(input: $input) {
      ...ManufacturerFullFragment
    }
  }
`)

export const SAVE_MATERIAL_TYPE = gql(`
  mutation SaveMaterialType($input: SaveMaterialTypeInput!) {
    saveMaterialType(input: $input) {
      ...MaterialTypeFragment
    }
  }
`)

export const UPDATE_MATERIAL_TYPE = gql(`
  mutation UpdateMaterialType($input: UpdateMaterialTypeInput!) {
    updateMaterialType(input: $input) {
      ...MaterialTypeFragment
    }
  }
`)

export const SAVE_ADDRESS = gql(`
  mutation SaveAddress($input: SaveAddressInput!) {
    saveAddress(input: $input) {
      ...AddressFragment
    }
  }
`)

export const UPDATE_ADDRESS = gql(`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      ...AddressFragment
    }
  }
`)

export const SAVE_WAREHOUSE = gql(`
  mutation SaveWarehouse($input: SaveWarehouseInput!) {
    saveWarehouse(input: $input) {
      ...WarehouseFragment
    }
  }
`)

export const UPDATE_WAREHOUSE = gql(`
  mutation UpdateWarehouse($input: UpdateWarehouseInput!) {
    updateWarehouse(input: $input) {
      ...WarehouseFragment
    }
  }
`)

export const SAVE_RESOURCES = gql(`
  mutation saveResources($input: SaveResourcesInput!) {
    saveResources(input: $input) {
      ...ResourceFragment
    }
  }
`)

export const UPDATE_RESOURCE = gql(`
  mutation updateResource($input: UpdateResourceInput!) {
    updateResource(input: $input) {
      ...ResourceFragment
    }
  }
`)

export const DELETE_RESOURCES = gql(`
  mutation deleteResources($input: DeleteResourcesInput!) {
    deleteResources(input: $input) {
      ...DeleteResourceFragment
    }
  }
`)

export const SEND_INVITATION = gql(`
  mutation sendInvitation($input: SendInvitationInput!) {
    sendInvitation(input: $input) {
      ...InvitationFragment
    }
  }
`)

export const DELETE_INVITATION = gql(`
  mutation deleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)
export const RESEND_INVITATION = gql(`
  mutation resendInvitation($input: ResendInvitationInput!) {
    resendInvitation(input: $input) {
      ...InvitationFragment
    }
  }
`)

export const ACCEPT_INVITATION = gql(`
  mutation acceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const SET_ACCOUNT_ACTIVE = gql(`
  mutation SetAccountActive($input: SetAccountActiveInput!) {
    setAccountActive(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const SAVE_WORK_CALENDAR_EXCEPTION_PERIOD = gql(`
  mutation SaveWorkCalendarExceptionPeriod($input: SaveWorkCalendarExceptionPeriodInput!) {
    saveWorkCalendarExceptionPeriod(input: $input) {
      ...WorkCalendarExceptionPeriodFragment
    }
  }
`)

export const UPDATE_WORK_CALENDAR_EXCEPTION_PERIOD = gql(`
  mutation UpdateWorkCalendarExceptionPeriod($input: UpdateWorkCalendarExceptionPeriodInput!) {
    updateWorkCalendarExceptionPeriod(input: $input) {
      ...WorkCalendarExceptionPeriodFragment
    }
  }
`)

export const DELETE_WORK_CALENDAR_EXCEPTION_PERIOD = gql(`
  mutation DeleteWorkCalendarExceptionPeriod($deleteWorkCalendarExceptionPeriodId: UUID!) {
    deleteWorkCalendarExceptionPeriod(id: $deleteWorkCalendarExceptionPeriodId) {
    ...EmptyResponseFragment
    }
  }
`)
